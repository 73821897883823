<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">订单号：</span>
        <el-input v-model="search.order_id" placeholder="请输入订单号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">账号：</span>
        <el-input v-model="search.account" placeholder="请输入账号" size="medium" clearable></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">交易类型：</span>
        <el-select v-model="search.title">
          <el-option value="" label="全部"></el-option>
          <el-option v-for="item in tradType" :key="item" :value="item.title" :label="item.title"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <span class="search-box-title">时间：</span>
        <el-date-picker v-model="search.searchDate" type="daterange" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
          value-format="YYYY-MM-DD"></el-date-picker>
      </div>
      <!-- <div class="search-box">
        <span class="search-box-title">精准：</span>
        <el-checkbox v-model="search.checked" :true-label="1" :false-label="0" size="medium"></el-checkbox>
      </div> -->
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="order" label="订单号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="account" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="nickname" label="昵称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="agent_account" label="上级账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="trad_time" label="交易时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="trad_type" label="交易类型" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="trad_price" label="交易金额" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="new_price" label="交易后金额" align="center" :show-overflow-tooltip="true"></el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
  </div>
</template>

<script>
import {getList, getTradType} from "@/api/finance/flows";
import page from "@/components/page";
export default {
  name: "financeFlows",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      search: {
        //搜索
        order_id: "",
        account: "",
        searchDate: [],
        // checked:1,
        title:"",
      },
      tradType:[

      ],
      tableData: [], //数据
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
    this.getTradType()
  },
  methods: {
    getTradType(){
      getTradType().then((res)=>{
        if (res.code == 0){
          this.tradType = res.data
        }
      })
    },
    //获取数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "order_id", val: this.search.order_id },
        { key: "account", val: this.search.account },
        // { key: "checked", val: this.search.checked },
        { key: "title", val: this.search.title },
        {
          key: "begin_time",
          val:
            this.search.searchDate.length > 0
              ? this.search.searchDate[0] + " 00:00:00" || ""
              : "",
        },
        {
          key: "end_time",
          val:
            this.search.searchDate.length > 0
              ? this.search.searchDate[1] + " 23:59:59" || ""
              : "",
        },
      ])
        .then((res) => {
          if (res.code == 0) {
            console.log(res);
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>